import App from 'next/app';
import {useRouter} from 'next/router';
import type {ReactElement} from "react";
import type {AppProps, AppContext} from 'next/app';
import {type SiteConfig} from '@uxu/utils/common/types';
import {GoogleTagManagerHead, GoogleTagManagerBody, GoogleAdManagerHead} from '@uxu/utils/frontend/google';
import {getHeaderClient} from '@uxu/utils/frontend/headers/nextjs';
import {WrapperProviders} from '@uxu/design-system/providers/provider.wrapper';
import {queryClient, siteConfig} from "../config";
import '@uxu/design-system/style/globalStyle.scss';


function CustomApp({Component, pageProps, client}: AppProps & { client: SiteConfig['client'] }): ReactElement {
    const config: SiteConfig = siteConfig;
    const {asPath} = useRouter();
    config.site.slug = asPath;
    config.client = client;


    return (
        <main className='app'>
            <WrapperProviders dehydratedState={pageProps.dehydratedState} queryClient={queryClient} siteConfig={config}>
                <GoogleTagManagerHead googleTagManagerId={config.marketingToolsConfig.googleTagManagerId}/>
                <GoogleTagManagerBody googleTagManagerId={config.marketingToolsConfig.googleTagManagerId}/>
                <GoogleAdManagerHead/>
                <Component {...pageProps} />
            </WrapperProviders>
        </main>
    );
}

CustomApp.getInitialProps = async (appContext: AppContext) => {
    const appProps = await App.getInitialProps(appContext);
    const {ctx} = appContext;
    const client = getHeaderClient(ctx);

    return {
        ...appProps,
        client,
    };
};


export default CustomApp;
