import type {SiteConfig, AdSlotsMap} from "@uxu/utils/common/types";
import {SiteIdEnums,} from "@uxu/utils/common/enums";

const adSlotsMap: AdSlotsMap = new Map([])

export const locale = 'pl';

export const siteConfig: SiteConfig = {
    marketingToolsConfig: {
        googleTagManagerId: 'GTM-KQXRK7L',
        adSlotsMap,
    },
    port: 3000,
    projectName: 'uxu',
    site: {
        id: SiteIdEnums.ADMIN,
        locale,
        domain: 'uxu.pl',
        slug: '/',
        brand: "uxu",
        shortBrand: "uxu",
        defaultCover: 'https://uxu.pl/defaultCover.png',
        canonicalUrl: process.env.NEXT_PUBLIC_SITE_URL || `https://uxu.pl`,
        images: [{url: 'https://uxu.pl/ogDefaultCover.png'}],
        title: 'Programista Paweł Niedźwiecki - UXU',
        shortname: 'wt',
        description: 'Full Stack Developer – JavaScript, Node.js, React.js, Go',
        authEnabled: false,
        switchTheme: false,
    },
    social: {
        facebook: {
            pageId: '61565800479632'
        },
        github: {
            accountName: 'pawel-niedzwiecki',
        },
        twitter: {
            accountName: 'p_niedzwiecki',
        },
        linkedin: {
            accountName: 'pawel-niedzwiecki',
        }
    },
    client: {
        locale,
        platform: {
            isDesktop: false,
            isMobile: false,
        },
        osInfo: {
            isWindows: false,
            isLinux: false,
            isMacOS: false,
        },
        cookieConsentSettings: {
            ads: false,
            analytics: false
        }
    },
    admin: {
        company: {
            name: "Paweł Niedźwiecki",
            street: "XXXXXXXXXXX",
            postCode: "66-400",
            city: "Gorzów Wielkopolski",
            contact: {
                pl: {
                    email: [{type: "main", email: "hello@uxu.pl"}],
                    phone: [{type: "mobile", number: "888 333 885"}]
                }
            }
        }
    },
    apps: {
        data: [],
        langs: []
    }
};
