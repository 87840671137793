import {vectorBrands} from "@uxu/assets";
import chamsko from '../../../../../public/icons/brand/chamsko.png'
import type {PortfolioItem} from './type';

export const historyItems: Array<PortfolioItem> = [
    {
        id: "uxu",
        typ: ['uxu'],
        title: "UXU - Full-stack Developer",
        description: 'Pracuję nad projektem, który zarządza bazą danych zawierającą ponad 180 milionów numerów telefonów w Polsce. Jako full-stack developer korzystam z technologii takich jak Go Lang, Redis, PostgreSQL oraz Next.js, aby tworzyć skalowalne i wydajne rozwiązania. Odpowiadam również za konfigurację środowisk z wykorzystaniem Kubernetes i Dockera oraz automatyzację procesów wdrażania za pomocą CI/CD. Równolegle realizuję mniejsze projekty jako freelancer, łącząc kompleksowe podejście do rozwoju z elastycznymi rozwiązaniami dopasowanymi do potrzeb klientów.',
        url: 'string',
        logo: {
            typ: 'svg',
            source: vectorBrands.uxu,
        }
    },
    {
        id: "grenton",
        typ: ['grenton'],
        title: "Grenton - Full-stack Developer",
        description: 'Jako full-stack developer współtworzyłem system konfiguracji inteligentnego domu oparty na technologiach React.js, Electron.js i Node.js. System pozwalał użytkownikom na wizualne tworzenie logiki działania inteligentnego domu za pomocą intuicyjnego interfejsu graficznego. Użytkownik mógł w prosty sposób definiować zasady i akcje, a system automatycznie generował kod w języku Lua, który był przesyłany do urządzenia zarządzającego automatyką domową. Wygenerowany kod odpowiadał za realizację zdefiniowanej logiki, umożliwiając efektywne i precyzyjne zarządzanie automatyką domu.',
        url: 'string',
        logo: {
            typ: 'svg',
            source: vectorBrands.grenton,
        }
    },
    {
        id: "wp",
        typ: ['wp'],
        title: "Wirtualna Polska - Frontend Web Developer",
        description: 'Jako Front-End Developer byłem odpowiedzialny za rozwój warstwy front-end oraz konfigurację Docker i Nginx dla serwisów o dużym ruchu, takich jak wiadomosci.wp.pl oraz kobieta.wp.pl. Współtworzyłem również autorską platformę front-end opartą na Node.js i działającą w architekturze SSR (Server-Side Rendering). Dodatkowo, przy projekcie wiadomosci.wp.pl pomagałem we wdrożeniu systemu live blogów opartego o Server-Side Events (SSE). Pracowałem z narzędziami takimi jak Confluence, Jira oraz Grafana, co umożliwiało efektywne zarządzanie projektami i monitorowanie systemów. Moje zadania obejmowały optymalizację front-endu, konfigurację serwerów oraz współpracę w środowisku agile.',
        url: 'string',
        logo: {
            typ: 'svg',
            source: vectorBrands.wp,
        }
    },
    {
        id: "autentika",
        typ: ['autentika'],
        title: "Autentika - Frontend Web Developer",
        description: 'Byłem wynajmowany na zasadzie body leasingu do Wirtualnej Polski przez firmę kontraktową/pośrednika. Oprócz realizacji projektów dla WP, uczestniczyłem w warsztatach związanych z UX, co pozwoliło mi lepiej zrozumieć interfejsy użytkownika i rozwijać się jako deweloper. Dodatkowo, pogłębiłem swoją wiedzę w zakresie tworzenia grafik wektorowych w Figmie, co poprawiło moją współpracę z zespołami projektowymi. Jednocześnie, dla Autentiki optymalizowałem stronę firmową pod kątem wydajności (performance).',
        url: 'string',
        logo: {
            typ: 'svg',
            source: vectorBrands.autentika,
        }
    },
    {
        id: "Na32",
        typ: ['Na32'],
        title: "Na3 - Frontend Web Developer",
        description: 'W ramach działalności freelancerskiej koncentruję się na projektach opartych o React.js, rozwijając swoje umiejętności w technologiach takich jak Node.js, MongoDB oraz PostgreSQL. Moim celem jest dalszy rozwój w kierunku full-stack developmentu oraz zdobycie biegłości w narzędziach konteneryzacyjnych, takich jak Docker.',
        url: 'string',
        logo: {
            typ: 'svg',
            source: vectorBrands.na3,
        }
    },
    {
        id: "plaincoded",
        typ: ['plaincoded'],
        title: "Plaincoded - Frontend Web Developer",
        description: 'Pracowałem nad stronami opartymi na Gatsby.js, bogatymi w animacje, które tworzyły interaktywne interfejsy użytkownika. Strony prezentowały luksusowe nieruchomości dla deweloperów budujących apartamenty premium w Londynie, podkreślając ekskluzywność inwestycji. Główny nacisk kładłem na płynność animacji i komfort użytkowników, dostosowując projekty do potrzeb wymagającej grupy docelowej.',
        url: 'string',
        logo: {
            typ: 'svg',
            source: vectorBrands.plaincoded,
        }
    },
    {
        id: "Na31",
        typ: ['Na31'],
        title: "Freelancer - Frontend Web Developer",
        description: 'Po kilku latach przerwy wróciłem do programowania, skupiając się na nauce JavaScript oraz tworząc proste strony w php. Moja pasja do web developmentu oraz dbałość o detale szybko pomogły mi w rozwijaniu umiejętności. Dzięki temu udało mi się pozyskać takich klientów jak PAGE Interactive, HL Tech, Quarticon i K2 Precise, dla których tworzyłem firmowe strony internetowe. Każdy projekt był dla mnie krokiem naprzód w doskonaleniu warsztatu i zrozumieniu procesu tworzenia stron.',
        url: 'string',
        logo: {
            typ: 'svg',
            source: vectorBrands.na3,
        }
    },
    {
        id: "chamsko",
        typ: ['chamsko'],
        title: "Chamsko.pl - Full-stack Developer",
        description: 'W 2013 roku stworzyłem i rozwijałem serwis chamsko.pl, który zgromadził 200 000 użytkowników dziennie. Serwis został w całości napisany przeze mnie w PHP.',
        url: 'string',
        logo: {
            typ: 'img',
            image: chamsko,
            sizes: {
                width: 200,
                height: 50
            }
        }
    },
    {
        id: "ceo",
        typ: ['ceo'],
        title: "CEO",
        description: 'NetworkCenter sp. z o.o. specjalizuję się w tworzeniu serwisów rozrywkowych, takich jak chamsko.pl, kreskoweczki.pl i wiele więcej oraz koncentruję się na optymalizacji działań SEO, aby zwiększyć widoczność i skuteczność moich projektów w internecie.',
        url: 'string',
        logo: {
            typ: 'svg',
            source: vectorBrands.cashfly,
        }
    },
    {
        id: "start",
        typ: ['start'],
        title: "Początki - Web Developer",
        description: 'W 2007 roku, jako hobby, uruchomiłem swoją pierwszą stronę internetową opartą na DataLife Engine (DLE), na której zbudowałem społeczność wymieniającą się wiedzą na temat frameworków, CMS-ów i narzędzi webowych. W tamtych czasach GitHub jeszcze nie istniał, więc platformy tego typu były niezbędne dla twórców stron internetowych.',
        url: 'string',
        logo: {
            typ: 'svg',
            source: vectorBrands.start,
        }
    },
]