import { HeaderMenuConfigTypes } from '@uxu/design-system/components/layout/types';


export const headerMenuConfig: HeaderMenuConfigTypes = {
    links: [
        { href: "/", title: "home" },
        { href: "/#portfolio", title: "portfolio" },
        { href: "/#about", title: "o mnie" },
        { href: "https://polski.dev", title: "blog" },
        { href: "/contact", title: "kontakt" }
    ],
    customBtn: [
        {
            url: "https://admin.uxu.pl",
            title: "admin",
            className: "primary"
        }
    ],
}

